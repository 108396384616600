<template>
    <NebulaGridRow type="flex" class="settings-section__row">
        <NebulaColumn :columns="4" :mdSize="8">
            <NebulaTitleBlock
                class="settings-section__title-section"
                :title="title"
                type="section"
                :description="description"
            />
        </NebulaColumn>
        <NebulaColumn :columns="8" :mdSize="8">
            <div v-if="label" class="settings-section__column-label-row">
                <h2 class="settings-section__column-label">{{ label }}</h2>
            </div>
            <slot />
        </NebulaColumn>
    </NebulaGridRow>
</template>

<script>
import {
    NebulaGrid,
    NebulaTitleBlock,
} from '@discoveryedu/nebula-components';

export default {
    name: 'SettingsSection',
    components: {
        NebulaGridRow: NebulaGrid.NebulaGridRow,
        NebulaColumn: NebulaGrid.NebulaColumn,
        NebulaTitleBlock,
    },
    props: {
        title: String,
        description: String,
        label: String,
    },
};
</script>

<style lang="stylus">
.settings-section {
    &__column-label {
        nebula-text-body-2();
        color: $nebula-color-platform-neutral-900;
        margin: 0;
    }

    &__column-label-row {
        border-block-end: 1px solid $nebula-color-platform-neutral-300;
        display: flex;
        justify-content: flex-end;
        padding-block-end: $nebula-space-3x;
    }

    &__title-section {
        --nebula-font-size-header-level-5: $nebula-font-size-card-title;

        @media (min-width: $nebula-breakpoints-desktop) {
            padding-inline-end: $nebula-space-2x;
        }
        .nebula-title-block__description {
            margin-block-start: $nebula-space-half;
            font-size: $nebula-font-size-body-1;
        }
    }

    &__row {
        padding-block-start: $nebula-space-5x;
        border-block-start: 1px solid $nebula-color-platform-neutral-300;
        margin-block-start: $nebula-space-5x;

        &:first-child {
            padding-block-start: 0;
            border-block-start: none;
            margin-block-start: 0;
        }

        &:last-child {
            margin-block-end: $nebula-space-10x;
        }
    }
}
</style>
