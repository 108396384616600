var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.loading)?_c('GrayBoxLoader',{staticClass:"notification-settings__loader"}):_c('div',{staticClass:"notification-settings"},[_c('SettingsSection',{attrs:{"title":"Email Notifications","description":"Select when you'll be notified when the following changes occur."}},[_c('SettingRow',{attrs:{"right":"","data":{
                setting: _vm.emailSettingAll,
                destination: 'email',
                type: ['all'],
                display: _vm.$t('select-all', { ns: 'settings' })
            }},on:{"setting-changed":_vm.toggleSetting}}),_vm._l((_vm.settings.email),function(each,idx){return _c('div',{key:`setting-email-${idx}`},[_c('SettingRow',{attrs:{"data":{ ...each, destination: 'email' }},on:{"setting-changed":_vm.toggleSetting}})],1)})],2),_c('SettingsSection',{attrs:{"title":"App Notifications","description":"Select when you'll be notified when the following changes occur."}},[_c('SettingRow',{attrs:{"right":"","data":{
                setting: _vm.appSettingAll,
                destination: 'app',
                type: ['all'],
                display: 'Select All'
            }},on:{"setting-changed":_vm.toggleSetting}}),_vm._l((_vm.settings.app),function(each,idx){return _c('div',{key:`setting-app-${idx}`},[_c('SettingRow',{attrs:{"data":{ ...each, destination: 'app' }},on:{"setting-changed":_vm.toggleSetting}})],1)})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }