<template>
    <PageWrapper class="settings" pageTitle="Settings and Preferences">
        <NebulaGridRow type="flex" v-if="false">
            <NebulaColumn :columns="12" :mdSize="8" class="settings__tabs-row">
                <NebulaTabs
                    aria-label="setting-navigation"
                    :useFlatButton="false"
                    :noBorderBottom="false"
                >
                    <template v-slot:tabs>
                        <NebulaTabItem
                            v-for="(tab, index) in tabs"
                            :key="index"
                            :tab="tab"
                            :selected="tabActive === index"
                            @click.native="handleTabClick(tab, index)"
                        />
                    </template>
                </NebulaTabs>
            </NebulaColumn>
        </NebulaGridRow>
        <AvailabilitySettings v-if="flags['pause-direct-requests']" />
        <NotificationSettings />
    </PageWrapper>
</template>

<script>
import PageWrapper from '@/components/shared/layout/PageWrapper.vue';
import {
    NebulaGrid,
    NebulaTabs,
    NebulaTabItem,
} from '@discoveryedu/nebula-components';
import NotificationSettings from '@/components/settings/NotificationSettings.vue';
import AvailabilitySettings from '@/components/settings/AvailabilitySettings.vue';

const tabs = [
    {
        value: 'my-details',
        name: 'My Details',
    },
    {
        value: 'availability',
        name: 'Availability',
    },
    {
        value: 'notifications',
        name: 'Notifications',
    },
];
export default {
    name: 'SettingsView',
    components: {
        AvailabilitySettings,
        NotificationSettings,
        PageWrapper,
        NebulaGridRow: NebulaGrid.NebulaGridRow,
        NebulaColumn: NebulaGrid.NebulaColumn,
        NebulaTabs,
        NebulaTabItem,
    },
    computed: {
        flags() {
            return this.$store.getters.flags;
        },
    },
    data() {
        return {
            tabs,
            tabActive: null,
            loading: true,
        };
    },
    methods: {
        handleTabClick(tab, index) {
            if (this.tabActive === index) {
                return;
            }

            this.tabActive = index;
        },
    },
};
</script>

<style lang="stylus">
.settings {
    &__tabs-row {
        border-bottom: 1px solid $nebula-color-platform-interactive-300;
    }
}
</style>
