<template>
    <GrayBoxLoader class="availability-settings__loader" v-if="loading" />
    <div v-else-if="settings && settings.length > 0" class="availability-settings">
        <SettingsSection
            :title="$t('direct-requests-header', { ns: 'settings' })"
            :label="$t('toggle-column-label', { ns: 'settings' })"
            :description="$t('direct-requests-description', { ns: 'settings' })"
        >
            <SettingRow
                class="availability-settings__row"
                v-for="(each, idx) in settings"
                :key="`simple-setting-${idx}`"
                :data="{
                    ...each
                }"
                @setting-changed="toggleSimpleSetting"
            />
        </SettingsSection>
    </div>
</template>

<script>
import GrayBoxLoader from '@/components/shared/Loader/GrayBox.vue';
import { SettingRow, SettingsSection } from '@/components/settings/shared';
import createPayload from '@/mixins/data/createPayload';

export default {
    name: 'AvailabilitySettings',
    mixins: [createPayload],
    components: {
        SettingRow,
        SettingsSection,
        GrayBoxLoader,
    },
    data() {
        return {
            loading: true,
        };
    },
    computed: {
        appMode() {
            return this.$store.state.app.mode;
        },
        settings() {
            if (this.$store.getters.settings) {
                return this.$store.getters.settings.simple;
            }
            return [];
        },
    },
    methods: {
        async toggleSimpleSetting(data) {
            const togglePayload = await this.createPayload({
                ...data,
            });
            await this.$store.dispatch('toggleSimpleSettings', togglePayload);
        },
    },
    async mounted() {
        const payload = await this.createPayload();
        const simpleSettingsFetch = () => this.$store.dispatch('getSimpleSettings', payload);

        await Promise.all([
            simpleSettingsFetch(),
        ]);
        this.loading = false;
    },
};
</script>

<style lang="stylus">
.availability-settings {
    width: 100%;
    // border-block-end: 1px solid $nebula-color-platform-neutral-300;
    // margin-block-end: $nebula-space-5x;

    &:last-child {
        padding-block-end: 0;
        border-block-end: none;
    }
    &__row {
        &:first-child {
            border-block-start: 1px solid $nebula-color-platform-neutral-300;
        }
    }
}
</style>
